import React from 'react';



const SignUp = () => {

    return (
        <>
        <div className="modal fade" id="signupModal" tabIndex="-1" role="dialog" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      {/* Header */}
      <div className="modal-close">
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      {/* End Header */}

      {/* Body */}
      <div className="modal-body">
        {/* Log in */}
        <div id="signupModalFormLogin" style={{ display: 'none', opacity: 0 }}>
          {/* Heading */}
          <div className="text-center mb-7">
            <h2>Log in</h2>
            <p>
              Don't have an account yet?
              <a
                className="js-animation-link link"
                href="javascript:;"
                role="button"
                data-hs-show-animation-options='{
                  "targetSelector": "#signupModalFormSignup",
                  "groupName": "idForm"
                }'
              >
                Sign up
              </a>
            </p>
          </div>
          {/* End Heading */}

          <div className="d-grid gap-2">
            <a className="btn btn-white btn-lg" href="#">
              <span className="d-flex justify-content-center align-items-center">
                <img className="avatar avatar-xss me-2" src="assets/svg/brands/google-icon.svg" alt="Google" />
                Log in with Google
              </span>
            </a>

            <a
              className="js-animation-link btn btn-primary btn-lg"
              href="#"
              data-hs-show-animation-options='{
                "targetSelector": "#signupModalFormLoginWithEmail",
                "groupName": "idForm"
              }'
            >
              Log in with Email
            </a>
          </div>
        </div>
        {/* End Log in */}

        {/* Log in with Modal */}
        <div id="signupModalFormLoginWithEmail" style={{ display: 'none', opacity: 0 }}>
          {/* Heading */}
          <div className="text-center mb-7">
            <h2>Log in</h2>
            <p>
              Don't have an account yet?
              <a
                className="js-animation-link link"
                href="javascript:;"
                role="button"
                data-hs-show-animation-options='{
                  "targetSelector": "#signupModalFormSignup",
                  "groupName": "idForm"
                }'
              >
                Sign up
              </a>
            </p>
          </div>
          {/* End Heading */}

          <form className="js-validate needs-validation" noValidate>
            {/* Form */}
            <div className="mb-3">
              <label className="form-label" htmlFor="signupModalFormLoginEmail">Your email</label>
              <input
                type="email"
                className="form-control form-control-lg"
                name="email"
                id="signupModalFormLoginEmail"
                placeholder="email@site.com"
                aria-label="email@site.com"
                required
              />
              <span className="invalid-feedback">Please enter a valid email address.</span>
            </div>
            {/* End Form */}

            {/* Form */}
            <div className="mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <label className="form-label" htmlFor="signupModalFormLoginPassword">Password</label>
                <a
                  className="js-animation-link form-label-link"
                  href="javascript:;"
                  data-hs-show-animation-options='{
                    "targetSelector": "#signupModalFormResetPassword",
                    "groupName": "idForm"
                  }'
                >
                  Forgot Password?
                </a>
              </div>

              <input
                type="password"
                className="form-control form-control-lg"
                name="password"
                id="signupModalFormLoginPassword"
                placeholder="8+ characters required"
                aria-label="8+ characters required"
                required
                minLength="8"
              />
              <span className="invalid-feedback">Please enter a valid password.</span>
            </div>
            {/* End Form */}

            <div className="d-grid mb-3">
              <button type="submit" className="btn btn-primary form-control-lg">Log in</button>
            </div>
          </form>
        </div>
        {/* End Log in with Modal */}

        {/* Sign up */}
        <div id="signupModalFormSignup">
          {/* Heading */}
          <div className="text-center mb-7">
            <h2>Sign up</h2>
            <p>
              Already have an account?
              <a
                className="js-animation-link link"
                href="javascript:;"
                role="button"
                data-hs-show-animation-options='{
                  "targetSelector": "#signupModalFormLogin",
                  "groupName": "idForm"
                }'
              >
                Log in
              </a>
            </p>
          </div>
          {/* End Heading */}

          <div className="d-grid gap-3">
            <a className="btn btn-white btn-lg" href="#">
              <span className="d-flex justify-content-center align-items-center">
                <img className="avatar avatar-xss me-2" src="assets/svg/brands/google-icon.svg" alt="Google" />
                Sign up with Google
              </span>
            </a>

            <a
              className="js-animation-link btn btn-primary btn-lg"
              href="#"
              data-hs-show-animation-options='{
                "targetSelector": "#signupModalFormSignupWithEmail",
                "groupName": "idForm"
              }'
            >
              Sign up with Email
            </a>

            <div className="text-center">
              <p className="small mb-0">By continuing you agree to our <a href="#">Terms and Conditions</a></p>
            </div>
          </div>
        </div>
        {/* End Sign up */}

        {/* Sign up with Modal */}
        <div id="signupModalFormSignupWithEmail" style={{ display: 'none', opacity: 0 }}>
          {/* Heading */}
          <div className="text-center mb-7">
            <h2>Sign up</h2>
            <p>
              Already have an account?
              <a
                className="js-animation-link link"
                href="javascript:;"
                role="button"
                data-hs-show-animation-options='{
                  "targetSelector": "#signupModalFormLogin",
                  "groupName": "idForm"
                }'
              >
                Log in
              </a>
            </p>
          </div>
          {/* End Heading */}

          <form className="js-validate need-validate" noValidate>
            {/* Form */}
            <div className="mb-3">
              <label className="form-label" htmlFor="signupModalFormSignupEmail">Your email</label>
              <input
                type="email"
                className="form-control form-control-lg"
                name="email"
                id="signupModalFormSignupEmail"
                placeholder="email@site.com"
                aria-label="email@site.com"
                required
              />
              <span className="invalid-feedback">Please enter a valid email address.</span>
            </div>
            {/* End Form */}

            {/* Form */}
            <div className="mb-3">
              <label className="form-label" htmlFor="signupModalFormSignupPassword">Password</label>
              <input
                type="password"
                className="form-control form-control-lg"
                name="password"
                id="signupModalFormSignupPassword"
                placeholder="8+ characters required"
                aria-label="8+ characters required"
                required
              />
              <span className="invalid-feedback">Your password is invalid. Please try again.</span>
            </div>
            {/* End Form */}

            {/* Form */}
            <div className="mb-3">
              <label className="form-label" htmlFor="signupModalFormSignupConfirmPassword">Confirm password</label>
              <input
                type="password"
                className="form-control form-control-lg"
                name="confirmPassword"
                id="signupModalFormSignupConfirmPassword"
                placeholder="8+ characters required"
                aria-label="8+ characters required"
                required
              />
              <span className="invalid-feedback">Password does not match the confirm password.</span>
            </div>
            {/* End Form */}

            <div className="d-grid mb-3">
              <button type="submit" className="btn btn-primary form-control-lg">Sign up</button>
            </div>

            <div className="text-center">
              <p className="small mb-0">By continuing you agree to our <a href="#">Terms and Conditions</a></p>
            </div>
          </form>
        </div>
        {/* End Sign up with Modal */}

        {/* Reset Password */}
        <div id="signupModalFormResetPassword" style={{ display: 'none', opacity: 0 }}>
          {/* Heading */}
          <div className="text-center mb-7">
            <h2>Forgot password?</h2>
            <p>Enter the email address you used when you joined and we'll send you instructions to reset your password.</p>
          </div>
          {/* End Heading */}

          <form className="js-validate need-validate" noValidate>
            <div className="mb-3">
              <label className="form-label" htmlFor="signupModalFormResetPasswordEmail">Your email</label>
              <input
                type="email"
                className="form-control form-control-lg"
                name="email"
                id="signupModalFormResetPasswordEmail"
                placeholder="email@site.com"
                aria-label="email@site.com"
                required
              />
              <span className="invalid-feedback">Please enter a valid email address.</span>
            </div>

            <div className="d-grid mb-3">
              <button type="submit" className="btn btn-primary form-control-lg">Reset Password</button>
            </div>
          </form>
        </div>
        {/* End Reset Password */}
      </div>
      {/* End Body */}
    </div>
  </div>
</div>

        
        </>
    );
}

export default SignUp;
