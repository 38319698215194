import React from "react";
import NavbarTopbar from "./NavbarTopbar";
import NavbarWrap from "./NavbarWrap";

const HeaderNoAuth = () => {
  return (
    <>
      <header
        id="header"
        className="navbar navbar-expand-lg navbar-end navbar-absolute-top navbar-light navbar-show-hide"
        data-hs-header-options='{
    "fixMoment": 1000,
    "fixEffect": "slide"
  }'
      >
        <NavbarTopbar />
        <NavbarWrap />

        
      </header>
    </>
  );
};
export default HeaderNoAuth;
