import React from 'react';
import Corps from '../Corps';
import Footer from '../Footer';
import SignUp from '../Signup';


const Home = () => {

    return (
        <div>
            <Corps/>
            <Footer/>
            <SignUp />
        </div>
    );
}

export default Home;
