import React from "react";


const CorpsMain = () => {
  return (
    <>
      <main id="content" role="main">
  {/* Hero */}
  <div className="d-lg-flex position-relative">
    <div className="container d-lg-flex align-items-lg-center content-space-t-3 content-space-lg-0 min-vh-lg-100">
      {/* Heading */}
      <div className="w-100">
        <div className="row">
          <div className="col-lg-5">
            <div className="mb-5">
              <h1 className="display-4 mb-3">
                Turn your ideas into a{" "}
                <span className="text-primary text-highlight-warning">
                  <span
                    className="js-typedjs"
                    data-hs-typed-options='{
                      "strings": ["startup.", "future.", "success."],
                      "typeSpeed": 90,
                      "loop": true,
                      "backSpeed": 30,
                      "backDelay": 2500
                    }'
                  ></span>
                </span>
              </h1>
              <p className="lead">
                Front's feature-rich designed demo pages help you create the
                best possible product.
              </p>
            </div>

            <div className="d-grid d-sm-flex gap-3">
              <a className="btn btn-primary btn-transition px-6" href="page-login-simple.html">
                Get started
              </a>
              <a className="btn btn-link" href="#">
                Learn more <i className="bi-chevron-right small ms-1"></i>
              </a>
            </div>
          </div>
          {/* End Col */}
        </div>
        {/* End Row */}
      </div>
      {/* End Title & Description */}

      {/* SVG Shape */}
      <div
        className="col-lg-7 col-xl-6 d-none d-lg-block position-absolute top-0 end-0 pe-0"
        style={{ marginTop: "6.75rem" }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1137.5 979.2">
          <path
            fill="#F9FBFF"
            d="M565.5,957.4c81.1-7.4,155.5-49.3,202.4-115.7C840,739.8,857,570,510.7,348.3C-35.5-1.5-4.2,340.3,2.7,389 c0.7,4.7,1.2,9.5,1.7,14.2l29.3,321c14,154.2,150.6,267.8,304.9,253.8L565.5,957.4z"
          />
          <defs>
            <path
              id="mainHeroSVG1"
              d="M1137.5,0H450.4l-278,279.7C22.4,430.6,24.3,675,176.8,823.5l0,0C316.9,960,537.7,968.7,688.2,843.6l449.3-373.4V0z"
            />
          </defs>
          <clipPath id="mainHeroSVG2">
            <use xlinkHref="#mainHeroSVG1" />
          </clipPath>
          <g clipPath="url(#mainHeroSVG2)">
            <image
              width="750"
              height="750"
              xlinkHref="assets/img/750x750/img2.jpg"
              transform="matrix(1.4462 0 0 1.4448 52.8755 0)"
            />
          </g>
        </svg>
      </div>
      {/* End SVG Shape */}
    </div>
  </div>
  {/* End Hero */}

  {/* Card Grid */}
  <div className="container content-space-2 content-space-t-xl-3 content-space-b-lg-3">
    {/* Heading */}
    <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5">
      <h2>Learn to develop sites with components and design systems</h2>
    </div>
    {/* End Heading */}

    <div className="text-center mb-10">
      {/* List Checked */}
      <ul className="list-inline list-checked list-checked-primary">
        <li className="list-inline-item list-checked-item">
          Asynchronous collaboration
        </li>
        <li className="list-inline-item list-checked-item">
          Updates and announcements
        </li>
        <li className="list-inline-item list-checked-item">Training and team building</li>
      </ul>
      {/* End List Checked */}
    </div>

    <div className="row mb-5 mb-md-0">
      <div className="col-sm-6 col-lg-4 mb-4 mb-lg-0">
        {/* Card */}
        <div className="card card-sm h-100">
          <div className="p-2">
            <img className="card-img" src="assets/img/480x220/img1.jpg" alt="Image Description" />
          </div>
          <div className="card-body">
            <h4 className="card-title">Front App</h4>
            <p className="card-text">Front App is an easier way to search on Android.</p>
            <ul className="list-pointer mb-0">
              <li className="list-pointer-item">Advanced Analytics</li>
              <li className="list-pointer-item">Digital Marketing</li>
              <li className="list-pointer-item">Organization</li>
            </ul>
          </div>
          <a className="card-footer card-link border-top" href="#">
            Learn more <i className="bi-chevron-right small ms-1"></i>
          </a>
        </div>
        {/* End Card */}
      </div>
      {/* End Col */}
      {/* Repeat for other cards */}
    </div>
    {/* End Row */}
  </div>
  {/* End Card Grid */}

  {/* Features */}
  <div className="position-relative bg-light rounded-2 mx-3 mx-lg-10">
    <div className="container content-space-2 content-space-lg-3">
      <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5">
        <h2>Wow your audience from the first second</h2>
        <p>The powerful and flexible theme for all kinds of businesses</p>
      </div>
      <div className="text-center mb-10">
        <ul className="list-inline list-checked list-checked-primary">
          <li className="list-inline-item list-checked-item">Responsive</li>
          <li className="list-inline-item list-checked-item">5-star support</li>
          <li className="list-inline-item list-checked-item">Constant updates</li>
        </ul>
      </div>

      <div className="row">
        <div className="col-lg-7 mb-9 mb-lg-0">
          <div className="pe-lg-6">
            {/* Browser Device */}
            <figure className="device-browser">
              <div className="device-browser-header">
                <div className="device-browser-header-btn-list">
                  <span className="device-browser-header-btn-list-btn"></span>
                  <span className="device-browser-header-btn-list-btn"></span>
                  <span className="device-browser-header-btn-list-btn"></span>
                </div>
                <div className="device-browser-header-browser-bar">
                  www.htmlstream.com/front/
                </div>
              </div>
              <div className="device-browser-frame">
                <img className="device-browser-img" src="assets/img/1618x1010/img6.jpg" alt="Image Description" />
              </div>
            </figure>
            {/* End Browser Device */}
          </div>
        </div>
        {/* More content goes here */}
      </div>
    </div>
  </div>
  {/* End Features */}
</main>

      
    </>
  );
};
export default CorpsMain;
