import React from "react";
import HeaderNoAuth from "../HeaderNoAuth";
import CorpsMain from "../CorpsMain.js";

const Corps = () => {
  return (
    <>
      <HeaderNoAuth />
      <CorpsMain />
    </>
  );
};
export default Corps;
